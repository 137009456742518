import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
    <footer className="bg-white">
        <div className="container mx-auto text-gray-800 px-8">
            <div className="w-full flex flex-col md:flex-row py-6">
                <div className="flex-1 text-center">
                    <p className="md:mb-6">&copy;Copyright {new Date().getFullYear()}</p>
                </div>
                <div className="flex-1 text-center">
                    <a
                        href="mailto:help@gradecorner.com"
                        className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                    >
                        <p className="md:mb-6">Contact Us</p>
                    </a>
                </div>
                <div className="flex-1 text-center">
                    <a
                        href="https://gradecorner.freshdesk.com/support/home"
                        className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                    >
                        <p className="md:mb-6">Support</p>
                    </a>
                </div>
                <div className="flex-1 text-center">
                    <Link
                        to="/privacy/"
                        className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                    >
                        <p className="md:mb-6">Privacy Policy</p>
                    </Link>
                </div>
            </div>
            <div className="text-center text-gray-400">
                <p>
                    Grade Corner is not affiliated, associated, authorized, endorsed by, or in any way
                    officially connected with the Follett Corporation who produce the Aspen™ Student
                    Information System
                </p>
            </div>
        </div>

        <a href="https://www.freepik.com/free-photos-vectors/background">
            Background vector created by freepik - www.freepik.com
        </a>
    </footer>
)

export default Footer
